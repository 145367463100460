import $ from 'jquery';

import Faq from './components/faq';


$(document).ready(function(){
    if ($('.faq').length) {
        let faq = new Faq;
        faq.init();
    }

    // Mobile Menu
    $('.js-mobile-menu').on('click', (event) => {
        event.preventDefault();

        $(event.currentTarget).toggleClass('open').siblings().toggleClass('open');
    });
    //---/ Mobile Menu
});