import $ from 'jquery';

class Faq {
    init() {
        console.log('faq initialized');
        $('.faq-title').on('click', (event) => {
            event.preventDefault();
            let title = $(event.currentTarget);

            title.toggleClass('active');
            title.siblings('.faq-content').slideToggle(250);
        });
    }
}

export default Faq;